:root {
	--background-color: #ffffff;
	--text-color: #000000;
	--button-background: #ffffff;
	--button-text-color: #000000;
	--button-border-color: #000000;
	--grey: #717171;
	--text-box: rgb(240, 240, 240);
	--hover: rgb(147, 147, 147);
	/* --hover: rgb(104, 195, 226); */
	--link: blue;
	--maxWidth: 950px;
	--headerHeight: 4em;
	--maxWidthMobile: 650px;
	--minWidthWeb: 651px;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

h1 {
	text-align: center;
}

h4 {
	max-width: 245px;
	margin: 20px auto;
	margin-top: 30px;
	text-align: justify;
	font-weight: bold;
}

.h4center {
	max-width: 245px;
	margin: 20px auto;
	margin-top: 30px;
	text-align: center;
	font-weight: bold;
}

h5 {
	max-width: 245px;
	margin: 20px auto;
	text-align: center;
	margin-top: 30px;
	text-align: center;
	font-weight: bold;
}

.create label {
	text-align: center;
	font-size: 500;
	color: green;
}

.create input,
.create textarea,
.create select {
	text-align: center;
	font-size: 900;
	color: green;
}

.search-container {
	display: flex;
	align-items: center;
}

.text-area {
	width: 290px;
	height: 23px;
	font-size: 16px;
	border-radius: 7px;
	resize: none;
	background-color: var(--background-color);
	font-family: Arial, sans-serif;
	font-weight: bold;
	border: 1px solid var(--button-text-color);
	padding-left: 0.5em;
}

body {
	background-color: var(--background-color);
	color: var(--text-color);
}

body.focused {
	background-color: var(--text-box);
}

.text-area::placeholder {
	color: var(--grey);
}

.search-button {
	height: 30px;
	font-size: 16px;
	border: none;
	background-color: var(--button-background);
	border: 1px solid var(--button-border-color);
	color: var(--button-text-color);
	border-radius: 7px;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
}

/* .text-area:hover {
  border-color: var(--hover);
} */

/* .search-button:hover,
.size-input:hover {
  border-color: var(--hover);
  color: var(--hover);
} */

@media not all and (pointer: coarse), not all and (-webkit-touch-enabled: true) {
	/* Styles for non-iOS and non-Android devices on hover */
	.search-button:hover,
	.size-input:hover {
		border-color: var(--hover);
		color: var(--hover);
	}
}

.text-area:focus::placeholder {
	color: var(--button-text-color);
}

.text-area:focus {
	background-color: var(--text-box);
	outline: none;
}

a:link {
	color: var(--link);
}

a:visited {
	color: var(--link);
}

/* Hide scrollbar on all elements */
::-webkit-scrollbar {
	display: none;
}

.image-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 0.4rem; /* Lower the gap between grid items */
	justify-items: center;
	width: 325px; /* Adjust width to accommodate smaller images */
	margin: 0 auto;
}

.image-container img {
	width: 100px;
	height: 100px;
	object-fit: cover;
	border-radius: 20px;
	margin-bottom: 0; /* Remove bottom margin */
	border: 1px solid var(--text-color);
	transition: opacity 0.3s ease;
}

.image-container div {
	position: relative;
}

.image-container img:hover {
	opacity: 0.5;
}

.image-container .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 97.5%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	opacity: 0;
	transition: opacity 0.3s ease;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
}

.image-container .overlay .overlay-text {
	max-width: 97%; /* adjust as needed */
	word-wrap: break-word;
	/* padding: 0 5%; */
}

.image-container div:hover .overlay {
	opacity: 1;
}

/* touch device style */
.touch-device .image-container img:hover {
	opacity: 1; /* override the hover opacity */
}

.touch-device .image-container div:hover .overlay {
	opacity: 0; /* hide the overlay */
}

/* overlay shown style */
.image-container .overlay-shown {
	opacity: 1;
}

.octo-octo-logo {
	position: fixed;
	bottom: 5%; /* 6% */
	left: 50%;
	transform: translateX(-50%);
	width: 30px; /* 60px */
	height: 30px; /* 60px */
	z-index: 9999;
}

.octo-octo-logo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50px;
	border: 1px solid black;
	transition: opacity 0.3s ease;
}

/* App-dark.css */

.pagination-button {
	background: none;
	border: none;
	font-size: 22px;
	color: var(--text-colorwhite);
}

/* Auris Code */

.card {
	border-radius: 0.7em;
	padding: 2em;
	text-align: left;
	transition: 0.5s;
	background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
	box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1.5em;
	color: black;
}
.card img {
	width: 5em;
	height: 5em;
	color: black;
}
.card .card-text {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 0.5em;
	color: black;
}

/* Hide the default disclosure arrow in all browsers */
details summary {
	list-style: none; /* Remove any default list styling */
	cursor: pointer; /* Change cursor to pointer on hover */
}

/* Hide the arrow in Chrome, Safari, and Opera */
details summary::-webkit-details-marker {
	display: none;
}

/* Hide the arrow in Firefox */
details summary::marker {
	display: none;
}

.legal {
	width: 100%;
	max-width: 600px; /* Match this with .card's max-width */
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 1em;
}
.legal summary {
	cursor: pointer;
	font-weight: 500;
	font-size: 1.2em;
}
.legal .legal-header h1 {
	margin: 0;
}
.legal .legal-header p {
	margin: 0.5em 0;
}
.legal article {
	border-radius: 0.7em;
	padding: 2em;
	text-align: left;
	transition: 0.5s;
	background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
	box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: left;
}
.legal article h2 {
	margin-bottom: 0;
}
.legal article p {
	margin: 0.3em 0;
}

h2,
h3,
h4,
h5,
p,
table {
	font-size: 1.1em;
	margin: 0;
	padding: 0;
}

/* main {
  flex-grow: 1;
  padding: 2em;
  z-index: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 2em;
} */

* {
	scrollbar-width: thin;
	scrollbar-color: hsl(0, 0%, 100%) hsl(0, 0%, 100%);
}

/* Add these styles to your CSS */

.App {
	/* display: flex;
  flex-direction: row;
  justify-content: space-between; */
}

.center {
	max-width: 950px;
	margin: 0 auto; /* Centers the container horizontally */
	font-weight: 400;
	color: var(--text-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
}

main {
	/* flex-grow: 1;
  padding: 2em;
  z-index: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 2em; */
	align-items: center; /* Centers child elements horizontally */
}

/* CARD BASICS */
.basicCard {
	border-radius: 0.7em;
	padding: 2em;
	text-align: left;
	transition: var(--transition-medium);
	background: var(--gradient-transparant-white);
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
}

.basicCardLink {
	cursor: pointer;
}
.basicCardLink:hover {
	background: var(--gradient-semi-transparant-white);
	transition: var(--transition-medium);
	box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0);
}
.basicCardLink:active {
	background: var(--gradient-white);
	transition: var(--transition-fast);
	box-shadow: var(--standard-inset-box-shadow);
}

.features {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2em;
}

.feature {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 2em;
	align-items: flex-start;
}

@media screen and (max-width: 650px) {
	.feature {
		flex-wrap: wrap;
		justify-content: center;
		gap: 2em;
	}
}

@media screen and (min-width: 650px) {
	.feature {
		flex-direction: row;
		justify-content: center;
		gap: 2em;
		align-items: flex-start;
	}
}

.screenshot {
  border-radius: 0.7em;
}

@media screen and (max-width: 650px) {
  .screenshot {
    width: 100%;
    height: auto;
    max-width: 200px;
  }
}

@media screen and (min-width: 651px) {
  .screenshot {
    width: 13em;
    height: 25em;
  }
}

.feature-card {
	border-radius: 0.7em;
	padding: 2em;
	text-align: left;
	transition: var(--transition-medium);
	background: var(--gradient-transparant-white);
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 1em;
	align-items: left;
}

@media screen and (max-width: 650) {
	.feature-card {
		padding: 2em;
		width: 100%;
	}
}

@media screen and (min-width: 651) {
	.feature-card {
		padding: 3em;
		flex-grow: 1;
		height: 100%;
	}
}

.feature-card h2 {
	margin-bottom: 0.5em;
}

.feature-card img {
	width: 2em;
	height: 2em;
	border-radius: 0;
}

.feature-card-element {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 1em;
	align-items: center;
}
